<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import InvoiceDetails, { type Charge as InvoiceDetailsCharge } from '@/views/billing/invoicing/InvoiceDetails.vue'
import {
  useApproveInvoice,
  useGetInvoice,
  useSaveCharges,
  useUpdateInvoiceConfig,
  useUpdateInvoiceMeterReadings
} from '@/composables/billing/invoices'
import ContentLoader from '@/components/content-loader.vue'
import Button from '@/components/button-secondary.vue'
import ButtonSecondary from '@/components/button-secondary.vue'
import ButtonPrimary from '@/components/button-primary.vue'
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import HeroSplashError from '@/components/hero-splash-error.vue'
import { faFloppyDisk, faHandPointUp, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { ChargeType, InvoiceStatus } from '@/api/billing/constants'
import PageContent from '@/components/page-content.vue'
import ButtonHighlighted from '@/components/button-highlighted.vue'
import ModalConfirmApproveAndSend from '@/views/billing/invoicing/modal-confirm-approve-and-send.vue'
import { type InvoiceMeterReadings} from "@/api/billing/schemas"

const route = useRoute()
const router = useRouter()
const invoiceId = parseInt(route.params.invoiceId.toString())
const invoiceIdComputed = computed(() => invoiceId)
const confirmApproveAndSend = ref(false)

const { mutateAsync: approveInvoiceAsync } = useApproveInvoice()
const { data: invoiceData, isLoading: invoiceLoading, isError: invoiceDataHasError} = useGetInvoice(invoiceId)
const { mutateAsync: updateInvoiceChargesAsync } = useSaveCharges(invoiceId)
const { mutateAsync: updateInvoiceConfigAsync } = useUpdateInvoiceConfig()
const { mutateAsync: updateMeterReadingsAsync } = useUpdateInvoiceMeterReadings(invoiceId)

const { wrap } = useLoader()
const { addSuccess, addError } = useNotifications()


const invoice = computed(() => {
    return {
        id: invoiceData.value?.id ?? 0,
        billingConfigurationId: invoiceData.value?.billingConfigurationId ?? 0,
        assetCode: invoiceData.value?.assetCode ?? '0',
        invoiceStatusId: invoiceData.value?.invoiceStatusId ?? 0,
        invoiceNumber: invoiceData.value?.invoiceNumber ?? "",
        invoiceExternalReferenceNumber: invoiceData.value?.invoiceExternalReferenceNumber ?? "",
        accountReferenceNumber: invoiceData.value?.accountReferenceNumber ?? "",
        poNumber : invoiceData.value?.poNumber ?? "",
        invoiceDate: invoiceData.value?.invoiceDate ?? new Date(),
        periodStartDate:invoiceData.value?.periodStartDate ?? new Date(),
        periodEndDate: invoiceData.value?.periodEndDate ?? new Date(),
        gridTariff: invoiceData.value?.gridTariff ?? 0,
        totalEnergyConsumption: invoiceData.value?.totalEnergyConsumption ?? 0,
        totalGridConsumption: invoiceData.value?.totalGridConsumption ?? 0,
        totalEnergyExported: invoiceData.value?.totalEnergyExported ?? 0,
        totalAmountExcludingTax: invoiceData.value?.totalAmountExcludingTax ?? 0,
        tax: invoiceData.value?.tax ?? 0,
        taxPercentage: invoiceData.value?.taxPercentage ?? 0,
        totalAmountIncludingTax: invoiceData.value?.totalAmountIncludingTax ?? 0,
        totalSavingsToDate: invoiceData.value?.totalSavingsToDate ?? 0,
        showMeterReadings: invoiceData.value?.showMeterReadings ?? false,
        showSavings: invoiceData.value?.showSavings ?? false,
        dueDate: invoiceData.value?.dueDate ?? new Date(),
        isExportPower: invoiceData.value?.isExportPower ?? false,
        trackingCategoryReference: invoiceData.value?.trackingCategoryReference ?? "",
        customer: {
          customerExternalIdentifier: invoiceData.value?.customer?.customerExternalIdentifier ?? "",
          name: invoiceData.value?.customer?.name ?? "",
          addressLineOne: invoiceData.value?.customer?.addressLineOne ?? "",
          addressLineTwo: invoiceData.value?.customer?.addressLineTwo ?? "",
          suburb: invoiceData.value?.customer?.suburb ?? "",
          city: invoiceData.value?.customer?.city ?? "",
          region: invoiceData.value?.customer?.region ?? "",
          country: invoiceData.value?.customer?.country ?? "",
          vatNumber: invoiceData.value?.customer?.vatNumber ?? ""
        },
        contractFundEntity: {
          id : invoiceData.value?.contractFundEntity.id ?? 0,
          name : invoiceData.value?.contractFundEntity.name ?? "",
          registrationNumber : invoiceData.value?.contractFundEntity.registrationNumber ?? "",
          address : invoiceData.value?.contractFundEntity.address ?? "",
          vatNumber : invoiceData.value?.contractFundEntity.vatNumber ?? "",
          xeroInvoiceAccountNumber : invoiceData.value?.contractFundEntity.xeroInvoiceAccountNumber ?? "",
          contactEmailAddress : invoiceData.value?.contractFundEntity.contactEmailAddress ?? "",
          contactTelephoneNumber : invoiceData.value?.contractFundEntity.contactTelephoneNumber ?? "",
          bank : invoiceData.value?.contractFundEntity.bank ?? "",
          accountName : invoiceData.value?.contractFundEntity.accountName ?? "",
          accountNumber : invoiceData.value?.contractFundEntity.accountNumber.toString() ?? "",
          branchCode : invoiceData.value?.contractFundEntity.branchCode ?? ""
        },
        invoiceSavings:{
          id: invoiceData.value?.invoiceSavings?.id ?? 0,
          invoiceId: invoiceData.value?.invoiceSavings?.invoiceId ?? 0,
          amount: invoiceData.value?.invoiceSavings?.amount ?? 0
        },
        invoiceDocument:{
          id: invoiceData.value?.invoiceDocument?.id ?? 0,
          invoiceId:invoiceData.value?.invoiceDocument?.invoiceId ?? 0,
          documentId: invoiceData.value?.invoiceDocument?.documentId ?? 0,
          createDateTime: invoiceData.value?.invoiceDocument?.createDateTime ?? new Date,
          updateDateTime: invoiceData.value?.invoiceDocument?.updateDateTime ?? new Date,
          document:{
            id: invoiceData.value?.invoiceDocument?.document?.id ?? 0,
            fileFormat: invoiceData.value?.invoiceDocument?.document?.fileFormat  ?? "",
            documentType: invoiceData.value?.invoiceDocument?.document?.documentType  ?? "",
            contentAsBase64String: invoiceData.value?.invoiceDocument?.document?.contentAsBase64String  ?? "",
            createDateTime: invoiceData.value?.invoiceDocument?.document?.createDateTime  ?? new Date,
            updateDateTime: invoiceData.value?.invoiceDocument?.document?.updateDateTime  ?? new Date
          }
        },
        invoiceCharges: invoiceData.value?.invoiceCharges?? [],
        meterReadings:invoiceData.value?.meterReadings ?? [],
        invoiceDateFormat: invoiceData.value?.invoiceDateFormat??"yyyy-MM-dd"
    }
})

const customer = computed(() => {
    return {
        name: invoiceData.value?.customer?.name ?? "",
        address: `${invoiceData.value?.customer?.addressLineOne}, ${invoiceData.value?.customer?.addressLineTwo}, ${invoiceData?.value?.customer?.suburb}
                        ${invoiceData.value?.customer?.city}, ${invoiceData.value?.customer?.region}`,
        vatNumber: invoiceData.value?.customer?.vatNumber ?? "",
        customerExternalIdentifier: invoiceData.value?.customer?.customerExternalIdentifier ?? ""
    }
})

const poNumber = ref(invoiceData.value?.poNumber ?? "")

watch(
  invoiceData,
  (newValue) => {
    if (newValue !== undefined && poNumber.value === '') {
      poNumber.value = newValue.poNumber ?? ''
    }
  },
  { once: true }
)

const invoiceStatus = computed(() => invoiceData.value?.invoiceStatusId)

const owner = computed(() => {
    return {
        id: invoiceData.value?.contractFundEntity.id ?? 0,
        name: invoiceData.value?.contractFundEntity.name ?? "",
        address: invoiceData.value?.contractFundEntity.address ?? "",
        vatNumber: invoiceData.value?.contractFundEntity.vatNumber ?? "",
        bankDetails: {
            accountName: invoiceData.value?.contractFundEntity.accountName ?? "",
            bank: invoiceData.value?.contractFundEntity.bank ?? "",
            accountNumber: invoiceData.value?.contractFundEntity.accountNumber ?? "",
            branchCode: invoiceData.value?.contractFundEntity.branchCode ?? ""
        }
    }
})

const modifiedCharges = ref<(InvoiceDetailsCharge & { index: number })[]>([])
const mergedCharges = computed(
  () => (invoiceData.value?.invoiceCharges ?? []).map(
    (charge, index) => {
      const updated = modifiedCharges.value.find(x => x.index === index)

      if (!updated) {
        return charge
      }

      return {
        ...charge,
        description: updated.description,
        quantity: updated.quantity,
      }
    }
  )
)
function handleChargeUpdate(charge: InvoiceDetailsCharge, index: number) {
  const existingModifiedChargeIndex = modifiedCharges.value.findIndex(x => x.index === index)

  if (existingModifiedChargeIndex < 0) {
    modifiedCharges.value.push({ ...charge, index })
  } else {
    modifiedCharges.value[existingModifiedChargeIndex] = { ...charge, index }
  }
}

const handleShowMeterReadingsUpdate = (newShowMeterReadings: boolean) => {
      invoice.value.showMeterReadings = newShowMeterReadings
}

const handleShowSavings = (newShowSavings: boolean) => {
      invoice.value.showSavings = newShowSavings
}

const handlePoNumberUpdate = (newPoNumber: string) => {
    poNumber.value = newPoNumber
}
const handleMeterReadingsUpdate = (newMeterReadings: InvoiceMeterReadings []) => {
      invoice.value.meterReadings = newMeterReadings
}

const approveInvoice = () => wrap(
  () => approveInvoiceAsync(invoiceIdComputed)
).then(
  () => addSuccess({ title: 'Invoice approved successfully.' }),
  () => addError({ title: 'Invoice could not be approved.' })
)

const saveInvoice = () => wrap(
  async () => {
    const statusBeforeSave = invoiceStatus.value

    await updateInvoiceChargesAsync(
      mergedCharges.value.map(
        x => ({
          id: x.id!,
          quantity: x.quantity!,
          description: x.description,
        })
      )
    )

    await updateMeterReadingsAsync(invoice.value.meterReadings)

    await updateInvoiceConfigAsync({
      invoiceId: invoiceId,
      showMeterReadings: invoice.value.showMeterReadings,
      showSavings: invoice.value.showSavings,
      poNumber: poNumber
    })

    if (statusBeforeSave !== InvoiceStatus.Draft) {
      navigateToInvoiceDashboard()
    }
  }
)
  .then(
    () => addSuccess({ title: 'Invoice Updated' }),
    () => addError({ title: 'Unable to update invoice', message: 'Please try again' })
  )

const navigateToInvoiceDashboard = () => {
    router.push({ name: 'invoices.list' })
}

</script>

<template>
  <PageContent>
    <ContentLoader :loading="invoiceLoading"></ContentLoader>
    <HeroSplashError v-if="invoiceDataHasError" />
    <InvoiceDetails v-if="!invoiceLoading && !invoiceDataHasError" :is-dummy-invoice="false" heading="Tax Invoice"
                    :editable-charge-types="[InvoiceStatus.Draft, InvoiceStatus.ActionRequired].includes(invoice.invoiceStatusId) ? [ChargeType.Usage, ChargeType.Recurring] : []"
                    :removable-charge-types="[]"

                    @update:charge="handleChargeUpdate"
                    :charges="
                      mergedCharges.map(
                        x => ({
                          id: x.id,
                          chargeType: x.chargeType,
                          quantity: x.quantity,
                          description: x.description,
                          rateInFractionalCurrency: x.rate * 100
                        })
                      )
                    "

                    :invoice="invoice"
                    :customer="customer"
                    :owner="owner"
                    :showMeterReadings="invoice.showMeterReadings"
                    :showSavings="invoice.showSavings"
                    :poNumber="poNumber"
                    :meterReadings="invoice.meterReadings"
                    @update:showMeterReading="handleShowMeterReadingsUpdate"
                    @update:showSavings="handleShowSavings"
                    @update:poNumber="handlePoNumberUpdate"
                    @update:meterReadings="handleMeterReadingsUpdate">
    </InvoiceDetails>
    <div class="button-wrapper mt-4" v-if="!invoiceLoading">
      <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="saveInvoice" :disabled="invoiceStatus == InvoiceStatus.Approved ||
                                                                                                invoiceStatus == InvoiceStatus.Sent ||
                                                                                                invoiceStatus == InvoiceStatus.Paid">Save</ButtonPrimary>
      <Button :icon="faXmark" class="one-h-width" @click="navigateToInvoiceDashboard">Cancel</Button>
      <template v-if="invoiceStatus === InvoiceStatus.Draft">
        <div class="vr"></div>
        <ButtonHighlighted :icon="faHandPointUp" @click="approveInvoice">Approve</ButtonHighlighted>
        <ButtonSecondary outline="teal" :icon="faHandPointUp" @click="confirmApproveAndSend = true">Approve & Send</ButtonSecondary>
      </template>
      <span class="thank-you-text">Thank you!</span>
    </div>

    <ModalConfirmApproveAndSend
      v-if="confirmApproveAndSend"
      :invoice-id="invoiceIdComputed"
      @close="confirmApproveAndSend = false" />

  </PageContent>
</template>

<style lang='scss'>
  .button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    .thank-you-text {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
</style>
