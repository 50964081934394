<script lang="ts">
import { ChargeType } from '@/api/billing/constants'

export type Charge = {
  id?: number,
  description: string,
  rateInFractionalCurrency: number,
  quantity?: number,
  chargeType: ChargeType,
}
</script>

<script setup lang="ts">
import { computed, defineEmits, ref, watch } from 'vue'
import {
  formatNumber,
  formatToFractionalCurrency,
  formatToMainCurrency,
  getDayOrdinalSuffix
} from '@/helpers/formatting'
import { format } from 'date-fns/format'
import { faDatabase, faFileInvoice, faLink, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { MeterTypeNumeric } from '@/api/assets/constants'
import InputText from '@/components/input-text.vue'
import Button from '@/components/button-secondary.vue'
import BaseTooltip from '@/components/base-tooltip.vue'
import Card from '@/components/Card/card-container.vue'
import CardContent from '@/components/Card/card-content.vue'
import CardHeader from '@/components/Card/card-header.vue'
import CardHeaderItem from '@/components/Card/card-header-item.vue'
import InputNumber from '@/components/input-number.vue'
import DropDownContext from '@/components/dropdown-context.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import InputCheckbox from '@/components/input-checkbox.vue'
import { DisplayOptions, InvoiceStatus } from '@/api/billing/constants'
import modalLinkedMeters from '@/views/billing/invoicing/modal-linked-meters.vue'
import InputToggleGroup from '@/components/input-toggle-group.vue'
import { type Invoice, type InvoiceMeterReadings} from "@/api/billing/schemas";
import DropdownItem from '@/components/dropdown-item.vue'

const props = withDefaults(
  defineProps<{
    editableChargeTypes: ChargeType[]
    removableChargeTypes: ChargeType[]
    placeholderMessage?: string
    capabilities?: ('charges:create')[]


    heading: string,
    isDummyInvoice: boolean,
    invoice: Invoice,
    showMeterReadings: boolean,
    showSavings: boolean,
    poNumber: string,
    customer: {
        name: string,
        customerExternalIdentifier: string,
        address: string,
        vatNumber: string
    },
    owner: {
        id: number,
        name: string,
        address: string,
        vatNumber: string,
        bankDetails: {
            accountName: string,
            bank: string,
            accountNumber: string,
            branchCode: string
        }
    },
    charges: Charge[],
    meterReadings: InvoiceMeterReadings []
  }>(),
  { capabilities: () => [] }
)

const toggleDisplayOptions = [
  { value: DisplayOptions.Show, text: 'Show' },
  { value: DisplayOptions.Hide, text: 'Hide' }
]

const poNumber = ref<string>(props.poNumber)
const reactiveInvoice = ref<Invoice>(props.invoice)

const showLinkMeterModal = ref<boolean>(false)
const isInvoiceEditable = ref<boolean>(props.invoice.invoiceStatusId === InvoiceStatus.ActionRequired ||
  props.invoice.invoiceStatusId === InvoiceStatus.Draft)
const showMeterReadingSelectedOption = ref(props.showMeterReadings ? DisplayOptions.Show : DisplayOptions.Hide)
const showSavingsSelectedOption = ref(props.showSavings ? DisplayOptions.Show : DisplayOptions.Hide)


const emit = defineEmits<{
  (event: 'createCharge'): void
  (event: 'editCharge', chargeId: number) : void
  (event: 'removeCharge', charge: Charge, index: number): void
  (event: 'update:poNumber', value: string): void,
  (event: 'update:showMeterReading', value: boolean) : void,
  (event: 'update:showSavings', value: boolean): void,
  (event: 'update:charges', charges: Charge[]) : void,
  (event: 'update:charge', charge: Charge, index: number): void,
  (event: 'update:meterReadings', meterReadings : InvoiceMeterReadings []) : void
    }>()

let inputBlurTimeout: number|undefined = undefined

const updatingChargeIndex = ref<number>()

function updateCharge(index: number, newValues: Partial<Charge>) {
  const newChargeList = [...props.charges]
  const newCharge = { ...newChargeList[index], ...newValues }
  newChargeList[index] = newCharge

  emit('update:charge', newCharge, index)
  emit('update:charges', newChargeList)
}

function blurEditableField() {
  inputBlurTimeout = window.setTimeout(
    () => updatingChargeIndex.value = undefined
  )
}

function focusEditableField() {
  clearTimeout(inputBlurTimeout)
}

watch(() => props.invoice, (newInvoice) => {
  reactiveInvoice.value = newInvoice
}, {deep: true})

watch(() => props.showMeterReadings, (newShowMeterReadings) => {
  showMeterReadingSelectedOption.value = newShowMeterReadings ? DisplayOptions.Show : DisplayOptions.Hide
})

watch(() => props.showSavings, (newShowSavings) => {
  showSavingsSelectedOption.value = newShowSavings ? DisplayOptions.Show : DisplayOptions.Hide
})

watch(() => props.poNumber, (newPoNumber) => {
  poNumber.value = newPoNumber
})

const reactiveMeterReadings = ref(props.meterReadings.map(item => ({ ...item })));
watch(
  reactiveMeterReadings.value,
  (newMeterReadings) => {
    emit('update:meterReadings', newMeterReadings);
  }
);
watch(poNumber, (newPoNumber: string) => {
  emit('update:poNumber', newPoNumber)
})

watch(showMeterReadingSelectedOption, () => emit('update:showMeterReading', showMeterReadingsValue.value))
watch(showSavingsSelectedOption, () => emit('update:showSavings', showSavingsValue.value))

const showMeterReadingsValue = computed(() => showMeterReadingSelectedOption.value === DisplayOptions.Show)
const showSavingsValue = computed(() => showSavingsSelectedOption.value === DisplayOptions.Show)

const exportGridMeters = computed(() => {

  if(reactiveInvoice.value.isExportPower){
    return reactiveMeterReadings.value.filter((meterReading: InvoiceMeterReadings) => meterReading.meterType.id == MeterTypeNumeric.Grid)
  }

  return []
})
</script>

<template>
  <div class="wrapper">
  <modalLinkedMeters v-if="showLinkMeterModal" :invoice-id="reactiveInvoice.id" @close="showLinkMeterModal=false"></modalLinkedMeters>
  <div class="invoice-header">
      <div class="left">
        <h1 class="invoice-title">{{ heading }}</h1>

        <div class="invoice-details-wrapper">
          <div class="d-flex flex-column">
            <span class="invoice-details">Invoice Number</span>
            <span class="invoice-details">Invoice Date</span>
            <span class="invoice-details">Invoice Period</span>
          </div>
          <div class="d-flex flex-column" v-if="!isDummyInvoice">
            <span class="invoice-details is-placeholder">{{ reactiveInvoice.invoiceNumber }}</span>
            <span class="invoice-details is-placeholder">{{ format(reactiveInvoice.invoiceDate,reactiveInvoice.invoiceDateFormat)}}</span>
            <span class="invoice-details is-placeholder">{{ format(reactiveInvoice.periodStartDate,reactiveInvoice.invoiceDateFormat)}} - {{ format(reactiveInvoice.periodEndDate,reactiveInvoice.invoiceDateFormat)}}</span>
          </div>
          <div class="d-flex flex-column" v-else>
            <span class="invoice-details is-placeholder">{{ placeholderMessage }}</span>
            <span class="invoice-details is-placeholder">{{ placeholderMessage }}</span>
            <span class="invoice-details is-placeholder">{{ placeholderMessage }}</span>
          </div>
        </div>

        <span class="invoice-details is-placeholder name">{{ customer.name }}</span>
        <span class="invoice-details is-placeholder">{{ customer.address }}</span>
        <span class="vat-number">
          <p class="v-title">Vat No.</p>
          <p class="invoice-details is-placeholder">{{ customer.vatNumber }}</p>
        </span>

        <div class="d-flex align-items-center">
          <span class="po-number">PO No.</span>
          <div class="po-number-input-wrapper">
            <InputText v-model:value="poNumber" type="text" placeholder="Type PO no here" :disabled="!isInvoiceEditable"/>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="logo"></div>
        <span class="name">{{ owner.name }}</span>
        <span class="address">{{ owner.address }}</span>
        <span class="vat-number">
          <p class="v-title">Vat No.</p>
          <p class="invoice-details is-placeholder">{{ owner.vatNumber }}</p>
        </span>

        <div class="amount-card">
          <FontAwesomeIcon :icon="faFileInvoice" class="ms-2 fa-4x file-invoice" />

          <div class="card-text-wrapper">
            <span class="note-text">Your Bill This Month</span>
            <span class="amount-text" v-if="isDummyInvoice">R 0.00</span>
            <span class="amount-text" v-else>{{ formatToMainCurrency(reactiveInvoice.totalAmountIncludingTax) }}</span>
          </div>
          <div class="child-card">
            <div class="due-date-text">
              <span>Due on</span>
              <span v-if="isDummyInvoice">{{ getDayOrdinalSuffix(typeof reactiveInvoice.dueDate === 'string' ? new Date(reactiveInvoice.dueDate) : (reactiveInvoice.dueDate ?? new Date())) }} of each Month</span>
              <span v-else>{{ format(reactiveInvoice.dueDate,reactiveInvoice.invoiceDateFormat) }}</span>
            </div>
          </div>
        </div>
      </div>
  </div>
  <Card class="invoice-charges">
      <CardHeader>
        <CardHeaderItem>Invoice Charges</CardHeaderItem>
      </CardHeader>
      <CardContent>
        <table class="table table-no-bottom-border mb-2">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Billing</th>
              <th scope="col">Units</th>
              <th scope="col">Rate (c)</th>
              <th scope="col">Total (R)</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(charge, index) in charges" :key="charge.id">
                <td>
                  <InputText v-if="updatingChargeIndex === index && charge.chargeType !== ChargeType.Usage"
                             :value="charge.description"
                             @update:value="updateCharge(index, { description: $event })"
                             @focus="focusEditableField"
                             @blur="blurEditableField" />

                  <span v-else class="invoice-details is-placeholder">{{ charge.description }}</span>
                </td>
                <td>
                    <span class="invoice-details is-placeholder">
                      {{ charge.chargeType === ChargeType.OnceOff ? 'Once-Off' : charge.chargeType }}
                    </span>
                </td>
                <td>
                  <InputNumber v-if="updatingChargeIndex === index"
                               :value="charge.quantity"
                               @update:value="updateCharge(index, { quantity: $event })"
                               @focus="focusEditableField"
                               @blur="blurEditableField" />

                  <span class="invoice-details is-placeholder" v-else>
                    {{ placeholderMessage ?? charge.quantity }}
                  </span>
                </td>
                <td>
                    <span class="invoice-details is-placeholder">
                      {{ formatToFractionalCurrency(charge.rateInFractionalCurrency) }}
                    </span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder">
                    {{
                      typeof charge.quantity === 'number'
                        ? formatToMainCurrency(charge.quantity * charge.rateInFractionalCurrency / 100)
                        : placeholderMessage
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <DropDownContext v-if="editableChargeTypes.includes(charge.chargeType) || removableChargeTypes.includes(charge.chargeType)">
                    <DropdownItem v-if="editableChargeTypes.includes(charge.chargeType) && !isDummyInvoice" @click="updatingChargeIndex = index">Edit</DropdownItem>
                    <DropdownItem v-if="editableChargeTypes.includes(charge.chargeType) && isDummyInvoice && charge.id !== undefined" @click="$emit('editCharge', charge.id ?? 0)">Edit</DropdownItem>
                    <DropdownItem v-if="removableChargeTypes.includes(charge.chargeType)" @click="$emit('removeCharge', charge, index)">Delete</DropdownItem>
                  </DropDownContext>
                </td>
            </tr>
          </tbody>
        </table>

        <a v-if="capabilities.includes('charges:create')" href="javascript:void(0)" @click="$emit('createCharge')" :class="$style['new-charge']">+ New Charge</a>
    </CardContent>
      <footer class="card-footer position-relative">
        <div class="footer-grid" v-if="isDummyInvoice">
          <span class="footer-total">Amount (Excl. VAT) </span>
          <span class="text-right"> R 0.00 </span>
          <span class="footer-total">Total VAT ({{invoice.taxPercentage.toFixed(2)}}%) </span>
          <span class="text-right"> R 0.00 </span>
          <span class="footer-total"> Amount Due (Incl. VAT) </span>
          <span class="text-right"> R 0.00 </span>
        </div>
        <div class="footer-grid" v-else>
          <span class="footer-total">Amount (Excl. VAT)</span>
          <span class="text-right"> {{ formatToMainCurrency(reactiveInvoice.totalAmountExcludingTax) }}</span>
          <span class="footer-total">Total VAT ({{invoice.taxPercentage.toFixed(2)}}%)</span>
          <span class="text-right"> {{ formatToMainCurrency(reactiveInvoice.totalAmountIncludingTax - reactiveInvoice.totalAmountExcludingTax) }}</span>
          <span class="footer-total"> Amount Due (Incl. VAT)</span>
          <span class="text-right"> {{ formatToMainCurrency(reactiveInvoice.totalAmountIncludingTax)}}</span>
        </div>
      </footer>
    </Card>

    <Card class="meter-readings">
      <CardHeader>
        <CardHeaderItem>Meter Readings </CardHeaderItem>
        <CardHeaderItem>
            <Button v-if="!isDummyInvoice" :icon="faLink" @click="showLinkMeterModal=true" class="color-temp-white linked-meter-btn" :class="reactiveInvoice.invoiceStatusId == InvoiceStatus.ActionRequired ? 'linked-meter-btn-error' : ''">Linked Meters</Button>
            <InputToggleGroup :options="toggleDisplayOptions" option-text="text" :disabled="!isInvoiceEditable" v-model:selected="showMeterReadingSelectedOption"/>
            <BaseTooltip class="info-tooltip">
              <template #icon>
              <span>
                <FontAwesomeIcon class="color-temp" :icon="faQuestionCircle" />
              </span>
              </template>
              Show or hide this <br />
              section on the invoice
            </BaseTooltip>
        </CardHeaderItem>
      </CardHeader>
      <CardContent>
        <table class="table table-no-bottom-border">
          <thead>
            <tr>
              <th scope="col">Device ID</th>
              <th scope="col">Type</th>
              <th scope="col">Serial No.</th>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              <th scope="col">Difference</th>
              <th scope="col">Hide</th>
            </tr>
          </thead>
          <tbody>

              <tr v-for="(gridMeterReading, gridMeterIndex) in exportGridMeters" :key="gridMeterReading.id">
                <td >
                  <span class="invoice-details is-placeholder">{{gridMeterReading.deviceId}}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder">{{ gridMeterReading.meterType.name }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder">{{ gridMeterReading.serialNumber }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(gridMeterReading.reverseActiveStartReading) }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(gridMeterReading.reverseActiveEndReading) }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(gridMeterReading.reverseActiveEndReading - gridMeterReading.reverseActiveStartReading) }}</span>
                </td>
                <td class="text-right">
                    <InputCheckbox v-model:checked="reactiveMeterReadings[gridMeterIndex].hide" v-if="gridMeterReading.meterType.id == MeterTypeNumeric.Grid"/>
                </td>
              </tr>
            <tr v-for="(meterReading, index) in reactiveMeterReadings.sort((reading: InvoiceMeterReadings) => +reading.deviceId)" :key="meterReading.id">
                <td >
                  <span class="invoice-details is-placeholder">{{meterReading.deviceId}}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder">{{ meterReading.meterType.name }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder">{{ meterReading.serialNumber }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(meterReading.forwardActiveStartReading) }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(meterReading.forwardActiveEndReading) }}</span>
                </td>
                <td>
                  <span class="invoice-details is-placeholder" v-if="isDummyInvoice">{{ placeholderMessage }}</span>
                  <span class="invoice-details is-placeholder" v-else>{{ formatNumber(meterReading.forwardActiveEndReading - meterReading.forwardActiveStartReading) }}</span>
                </td>
                <td class="text-right">
                    <InputCheckbox v-model:checked="reactiveMeterReadings[index].hide" v-if="meterReading.meterType.id == MeterTypeNumeric.Grid"/>
                </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      <footer class="card-footer position-relative">
        <div class="footer-grid">
          <span class="footer-total"> Grid Consumption (kWh)</span>
          <span>{{ formatNumber(reactiveInvoice.totalGridConsumption) }}</span>
          <span class="footer-total"> PV Consumption (kWh) </span>
          <span> {{ formatNumber(reactiveInvoice.totalEnergyConsumption) }} </span>
          <span class="footer-total" v-if="reactiveInvoice.isExportPower">Export Power (KWh)</span>
          <span v-if="reactiveInvoice.isExportPower"> {{ formatNumber(reactiveInvoice.totalEnergyExported?? 0) }}</span>
        </div>
      </footer>
    </Card>

    <footer class="footer-wrapper">
      <div class="left-info">
        <span class="banking-details">BANKING DETAILS</span>
        <div class="invoice-details-wrapper">
          <div class="d-flex flex-column">
            <span class="invoice-details">Account Holder</span>
            <span class="invoice-details">Bank</span>
            <span class="invoice-details">Account No.</span>
            <span class="invoice-details">Branch Code</span>
          </div>
          <div class="d-flex flex-column">
            <!-- note -->
            <span class="invoice-details is-placeholder">{{ owner.bankDetails.accountName }}</span>
            <span class="invoice-details is-placeholder">{{ owner.bankDetails.bank }}</span>
            <span class="invoice-details is-placeholder">{{ owner.bankDetails.accountNumber }}</span>
            <span class="invoice-details is-placeholder">{{ owner.bankDetails.branchCode }}</span>
          </div>
        </div>
      </div>
      <div class="right-info">
        <div class="d-flex">
          <!--to be made a component-->
          <div class="toggle-container">
            <InputToggleGroup :options="toggleDisplayOptions" v-model:selected="showSavingsSelectedOption" :disabled="!isInvoiceEditable"/>
          </div>
          <BaseTooltip class="info-tooltip">
            <template #icon>
              <span>
                <FontAwesomeIcon class="color-temp" :icon="faQuestionCircle" />
              </span>
            </template>
            Show or hide this <br />
            section on the invoice
          </BaseTooltip>
        </div>
        <!--to be made a component-->
        <div class="footer-amount-card">
          <div class="invoice-details-wrapper">
            <div class="d-flex flex-column">
              <span class="invoice-details">This Invoice’s Savings</span>
              <span class="invoice-details">Savings to Date</span>
              <span class="invoice-details">Total Solar Cost (excl)</span>
            </div>
            <div class="d-flex flex-column">
              <span class="invoice-details is-placeholder">{{ formatToMainCurrency(reactiveInvoice.invoiceSavings?.amount??0) }}</span>
              <span class="invoice-details is-placeholder">{{ formatToMainCurrency(reactiveInvoice.totalSavingsToDate) }}</span>
              <span class="invoice-details is-placeholder">{{ formatToMainCurrency(reactiveInvoice.totalAmountExcludingTax)}}</span>
            </div>
          </div>

          <div class="child-card">
            <span class="summary-text">Summary</span>
          </div>
          <div class="position-relative">
            <div class="overlay"></div>
            <FontAwesomeIcon
              :icon="faDatabase"
              class="ms-2 fa-3x file-invoice floating-icon-two"
            />
            <FontAwesomeIcon
              :icon="faDatabase"
              class="ms-2 fa-3x file-invoice floating-icon"
            />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.new-charge {
  margin-top: 15px;
  text-decoration: none;
}
</style>

<style scoped lang="scss">
.name {
  font-size: 18px;
  text-transform: uppercase;
  color: #6d6e71;
  margin-bottom: 15px;
  font-weight: bold;
}

th {
  width: 223px;
}
th:last-child {
  width: 0px;
}

.input-number {
  width: 58px;
}

.toggle-container {
  margin-left: auto;
  width: 96px;
  height: 23px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.toggle-container button {
  color: #6d6e71;
  padding: 3px 10px;
  border: none;
  width: 50%;
  cursor: pointer;
  height: 23px;
  border-radius: 4px;
}

.toggle-container button.active {
  background: #fee351;
}

.color-temp {
  color: #b2b2b2;
}

.info-tooltip {
  margin-left: 5px;
}

.footer-grid {
  display: grid;
  align-items: baseline;
  row-gap: 0px;
  grid-template-columns: 167px 1fr;
  column-gap: 78px;
  font-weight: bold;
  color: #6d6e71;
  background: #eeeeee !important;
  // position: absolute;
  margin-bottom: -50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  padding: 20px;
  padding-left: 64.5%;
  .footer-total {
    text-align: right;
  }
}

.file-invoice {
  color: #fff;
  opacity: 0.4;
}

.color-temp-yellow {
  color: #fee351;
}

.padding-dropdown {
  margin-left: 10px;
}

.overlay {
  width: 30px;
  height: 30px;
  background: red;
  position: absolute;
  left: 0px;
  top: -10px;
  z-index: 6;
  background: #eeeeee;
}

.floating-icon {
  z-index: 5;
  left: 5px;
  top: -20px;
  position: absolute;
}
.floating-icon-two {
  z-index: 7;
  left: -9px;
  top: -13px;
  position: absolute;
}
.invoice-details {
  font-size: 12px;
  color: #6d6e71;

  &.is-placeholder {
    margin-right: 0;
    // margin-left: auto;
    opacity: 0.5;
  }
}

.wrapper {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tabs-wrapper {
    display: flex;
    gap: 30px;
    margin-block: 27px 34px;
    span {
      text-transform: uppercase;
      font-weight: bold;
      cursor: not-allowed;
      opacity: 50%;
      &.is-active {
        color: #28a09c;
        border-bottom: 2px solid #28a09c;
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .button-wrapper-header {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
  }

  .invoice-header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      text-align: left;
      color: #6d6e71;
      .invoice-title {
        margin-top: 85px;
        margin-bottom: 54px;
        font-size: 30px;
        font-weight: 900;
        text-align: left;
      }

      .invoice-details-wrapper {
        display: flex;
        gap: 52px;
        margin-bottom: 35px;
        .invoice-details {
          font-size: 12px;
          color: #6d6e71;

          &.is-placeholder {
            margin-right: 0;
            // margin-left: auto;
            opacity: 0.5;
          }
        }
      }

      .invoice-subtitle {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 16px;
        color: #6d6e71;
      }

      .address {
        font-size: 12px;
        color: #6d6e71;
        width: 248px;
        margin-right: auto;
        margin-bottom: 14px;
      }

      .vat-number {
        font-size: 12px;
        color: #6d6e71;
        display: flex;
        gap: 27px;
        margin-right: auto;
        margin-bottom: 10px;
        .v-title {
          font-weight: bold;
        }
      }

      .po-number {
        min-width: max-content;
        color: #6d6e71;
        margin-right: 27px;
      }

      .po-number-input-wrapper {
        width: 177px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-items: flex-end;

      .logo {
        height: 136px;
        width: 400px;
        margin-bottom: 14px;
      }

      .name {
        font-size: 18px;
        text-transform: uppercase;
        color: #6d6e71;
        text-align: right;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .address {
        font-size: 12px;
        color: #6d6e71;
        width: 248px;
        margin-left: auto;
        margin-bottom: 5px;
      }

      .vat-number {
        font-size: 12px;
        color: #6d6e71;
        display: flex;
        gap: 27px;
        margin-left: auto;
        .v-title {
          font-weight: bold;
        }
      }

      .amount-card {
        width: 406px;
        height: 106px;
        box-shadow: 2px 2px 4px #00000029;
        background: #6d6e71;

        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;

        // margin-top: 13px;
        border-radius: 10px;

        position: relative;

        .icon {
          // background: #b2b2b2;
          width: 30px;
          height: 40px;
        }

        .card-text-wrapper {
          display: flex;
          flex-direction: column;
          .note-text {
            font-size: 12px;
            color: #fff;
          }

          .amount-text {
            font-size: 25px;
            color: #fff;
          }
        }

        .child-card {
          width: 187px;
          height: 42px;
          background: #b2b2b2;
          border-radius: 6px;
          position: absolute;
          top: 81%;
          left: 46%;
          display: flex;
          align-items: center;
          justify-content: center;
          .due-date-text {
            display: flex;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            gap: 8px;
          }
        }
      }
    }
  }

  .invoice-charges {
    margin-top: 44px;
    padding-bottom: 50px;
    position: relative;
    margin-bottom:1%;
    :deep(.card-footer) {
      .footer-grid {
        display: grid;
        align-items: baseline;
        row-gap: 0px;
        grid-template-columns: 167px 1fr;
        column-gap: 78px;
        font-weight: bold;
        color: #6d6e71;
        background: #eeeeee !important;
        // position: absolute;
        margin-bottom: -50px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 100%;
        padding: 20px;
        padding-left: 64.5%;
      }
    }
  }
  .meter-readings {
    margin-bottom: 46px;
    padding-bottom: 50px;
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    .left-info {
      .banking-details {
        margin-bottom: 25px;
        font-weight: bold;
      }
      .invoice-details-wrapper {
        display: flex;
        gap: 52px;
        margin-bottom: 35px;
        margin-top: 25px;
      }
    }

    .right-info {
      .footer-amount-card {
        width: 408px;
        height: 106px;
        box-shadow: 2px 2px 4px #00000029;
        background: #eeeeee;

        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;

        // margin-top: 13px;
        border-radius: 10px;

        position: relative;
        margin-top: 5px;

        .invoice-details-wrapper {
          display: flex;
          gap: 52px;
          span,
          .invoice-details {
            font-size: 12px;
            color: #6d6e71;
            &.is-placeholder {
              opacity: 0.5;
              // margin-left: auto;
              margin-right: 0;
            }
          }
        }

        .icon {
          background: #b2b2b2;
          width: 30px;
          height: 40px;
        }

        .card-text-wrapper {
          display: flex;
          flex-direction: column;
          .note-text {
            font-size: 12px;
            color: #6d6e71;
          }

          .amount-text {
            font-size: 12px;
            color: #6d6e71;
          }
        }

        .child-card {
          width: 111px;
          height: 35px;
          background: #6d6e71;
          border-radius: 6px;
          position: absolute;
          top: -17%;
          left: 8%;
          display: flex;
          align-items: center;
          justify-content: center;
          .summary-text {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
    }
  }
  .linked-meter-btn{
    width: 123px;
    height: 24px;
    padding-top: 2px;
    margin-right: 20px;
    border-color: #41C8DB;
  }

  .linked-meter-btn-error{
    border-color: #FD0000;
  }
  .button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    .thank-you-text {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
}
</style>
